/* BookList.module.css */

.amazonLink {
  text-decoration: none;
}

.mobile-bookGenres{
  color: var(--text2);
  font-size: .7rem;
  padding: 0;

}

.bookList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.coverImage {
  width: 100px;
  height: 150px;
  object-fit: cover;
  margin-right: 1rem;
}

.bookInfo {
  flex-grow: 1;
}

.bookTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.1rem;
}

.bookAuthor {
  font-size: 1rem;
  color: var(--text2);
  margin-bottom: 0;
  line-height: .85; /* Adjust as needed */
}

.datePublished{
  color: var(--text2);
}
  
.desktop-bookCard {
  background-color: var(--card);
  color: var(--book-title);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.desktop-bookCard:hover {
  background-color: var(--card-hover);
}

.featured-bookCard {
  position: relative; /* Needed for absolute positioning of the label */
  margin-top: 0.5rem; /* Provide space for the label */
  background-color: var(--card);
  color: var(--book-title);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 3px purple; /* Simulate an inner border */
  cursor: pointer;
  /*border: 1px solid purple;*/
}

.featuredLabel {
  position: absolute;
  top: -0.85rem; /* Position label above the card */
  left: 1rem; /* Align with the left side of the card, adjust as needed */
  background-color: purple;
  color: white;
  padding: 0rem 0.7rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem ; /* Rounded corners */
  font-size: 0.7rem;
  font-weight: bold;
}

.mobile-bookCard {
  background-color: var(--card);
  color: var(--book-title);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* BookList.css */
.desktop-bookGenres {
  margin-top: 0.5rem;
  display: flex;          /* Use flexbox for inline arrangement */
  flex-wrap: wrap;        /* Allow wrapping of items */
  max-width: 250px;       /* Adjust the width as needed */
  row-gap: 10px;
  overflow: hidden; /* Hide any content that overflows */
  max-height: 65px; /* Height for exactly two lines */
}

.genreTag {
  display: inline-block;
  background-color: var(--tag);
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  border-radius: 15px;
  font-size: 0.75rem;
  color: var(--text2);
  cursor: default;
  font-weight: bold;
}

.genreTag:hover {
  background-color: var(--tag-hover);
}

.bookRatings{
  margin-right: 2%;
  margin-top: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

}

.ratingBadge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 10px;
  margin-bottom: 13px;
}

@media (max-width: 375px) {
  .ratingBadge {
    width: 16vw;
    height: 16vw;
    font-size: 4vw;
    margin-right: 0px;

  }

  .mobile-bookCard {
    padding: 3vw;
  }
}


.rating-4 { background-color: #4CAF50; }
.rating-3 { background-color: #8BC34A; }
.rating-2 { background-color: #FFC107; }

.no_ratings {
  color: var(--text2);
  font-size: 0.8em;
  margin-top: 1.5rem;
}

.no_reviews {
  color: var(--text2);
  font-size: 0.8em;
}

.custom-link {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Inherits the color from the parent element */
}

.amazon-div{
  display: flex; /* Enables Flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.amazon-logo {
  width: 80px; /* Adjusts the size of the image */
  height: 80px; /* Adjusts the size of the image */
  object-fit: cover; /* Ensures the image covers the area properly */
  margin-right: 20px;
}

.mobile-amazon-logo {
  width: 80px; /* Adjusts the size of the image */
  height: 80px; /* Adjusts the size of the image */
  object-fit: cover; /* Ensures the image covers the area properly */
}