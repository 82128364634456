html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.dark {
  height: 100%;
}
/* above is so dark theme is applied uniformly: root needs to take 100% of viewport because dark is applied to it first for some reason... avoid white flashes at bottom of about and promote pages*/

* {
  transition: background-color 0.4s ease, color 0.1s ease;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Define default (light theme) color variables */
.light {
  --bg: #fff;
  --nav: #eee;
  --text1: #000;
  --text2: #555;
  --book-title: #333;
  --card: #fff;
  --card-hover: #f3f3f3;
  --tag: rgb(0 0 0 / 8%);
  --tag-hover: #d0d0d0;
  background-color: var(--bg);
  color: var(--text1);
  --badge1: rgba(76, 175, 80, 0.75);
  --badge2: rgba(139, 195, 74, 0.75);
  --badge3: rgba(255, 193, 7, 0.75);
  --badge4: rgb(0 0 0 / 8%);
  --book-card-underlay: rgba(238, 238, 238, 0.85);
}

/* Override variables for dark theme */
.dark {
  --bg: #000;
  --nav: #222;
  --text1: #fff;
  --text2: #ccc;
  --book-title: #eee;
  --card: #222;
  --card-hover: #333;
  --tag: rgba(255, 255, 255, 0.16);
  --tag-hover: #6a6a6a;

  background-color: var(--bg);
  color: var(--text1);

  --badge1: rgba(76, 175, 80, 0.5);
  --badge2: rgba(139, 195, 74, 0.5);
  --badge3: rgba(255, 193, 7, 0.5);
  --badge4: black;

  --book-card-underlay: rgba(0, 0, 0, 0.8)
}