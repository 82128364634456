.title {
  text-align: center;
  font-family: "Palatino";
  font-weight: 400;
  margin-bottom: 0px;
}

.under {
  text-align: center;
  font-family: "Palatino";
  font-weight: 400;
  font-size: 13px;
  margin-top: 0px;
}

.btn-secondary {
  color: var(--text1) !important;
  background-color: var(--tag-hover) !important;
  margin-bottom: 10px;
}