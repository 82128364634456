/* RatingSlider.css */

#slider-round {
    height: 8px;
    border: transparent;
    flex-grow: 1;
}

#slider-round .noUi-connect {
    background: #2596be;
}

#slider-round .noUi-handle {
    background: white; /* Or any color you'd like for the handle */
    height: 20px;
    width: 20px;
    top: -6px;
    right: -10px;
    border-radius: 50%; /* Makes it a circle */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for depth */
    cursor: pointer; /* Changes the cursor on hover */
    outline: none; /* Removes the default focus outline */
}

#slider-fit {
    padding: 0 16px;
}

#slider-round .noUi-base {
    border-radius: 10px; /* Adjust this value for desired roundness */
}

#slider-round .noUi-handle::before,
#slider-round .noUi-handle::after {
    display: none !important; /* Hides the before and after pseudo-elements */
}

.light .noUi-tooltip {
    top: -150%;
    background: transparent !important; /* Remove background */
    color: black; /* Set text color */
    font-weight: bold; /* Make text bold */
    border: none !important; /* Remove any borders */
    box-shadow: none !important; /* Remove any box shadows */
    font-size: 14px; /* Adjust font size as needed */
}

.dark .noUi-tooltip {
    top: -150%;
    background: transparent !important; /* Remove background */
    color: rgb(255, 255, 255); /* Set text color */
    font-weight: bold; /* Make text bold */
    border: none !important; /* Remove any borders */
    box-shadow: none !important; /* Remove any box shadows */
    font-size: 14px; /* Adjust font size as needed */
}
