.dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/*toggle button transparent when selected*/
.dark .navbar-toggler {
  color: rgba(255, 255, 255, 0);
  border: 1px solid var(--tag-hover);
}

.navbar{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.nav-link{
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.navbar-brand {
  font-weight: bold;
}


/* Dark theme styles */
.navbar {
  background-color: var(--nav); /* Darker background for dark theme */
  color: var(--text2);
}

.navbar-brand {
  color: var(--text1) !important;
}

.navbar-brand:hover {
  color: var(--text1) /* Maintain the same color on hover */
}

.navbar-nav .nav-link {
  color: var(--text2);
}

.light .navbar-nav .nav-link:hover,
.light .navbar-nav .nav-item.active .nav-link {
  background-color: #e6e6e6; /* Slight grey for hover/active state */
  color: #000;
}

.dark .navbar-nav .nav-link:hover,
.dark .navbar-nav .nav-item.active .nav-link {
  background-color: #4d4d4d; /* Lighter dark for hover/active state */
  color: #fff;
}
