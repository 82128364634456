/* BookCard.css */
.book-card {
    perspective: 1000px; /* Gives a depth effect for the flip */
  }
  
  .book-card-inner {
    transition: transform 0.6s; /* Smooth transition for flipping */
    transform-style: preserve-3d; /* Ensures 3D flip effect */
    cursor: pointer; /* Changes cursor to indicate clickable area */
    position: relative; /* Necessary for absolute positioning of front and back */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  
  .flipped {
    transform: rotateY(-180deg); /* Flips the card when toggled */
  }

.book-front {
    transform: rotateY(-180deg);
    display: flex;
    justify-content: center;
}

.book-front .text{
  padding: 0px 15px;
  font-size: 11px;
  border: none;
  background-color: rgba(255, 255, 255, 0.7); /* Ensure this is transparent, not 'none' */
  color: black;
  border-radius: 20px;
  position: fixed;
  bottom: 0px;
  transition: background-color 0.3s; /* Animation for background color change */
  margin-bottom: 8px;
}

.hide{
  display: none;
}
  
.book-back {
    backface-visibility: hidden; /* Hides the opposite side when flipped */
    position: absolute; /* Positions front and back on top of each other */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px; /* Padding for text */
    z-index: 0 !important;
    perspective: 0px;

  }

  .book-back::before {
    content: ''; /* Required for a pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--book-card-underlay); /* Semi-transparent black overlay */
    z-index: 1 !important; /* Ensures it sits above the background image but below the text */
    border-radius: 4px;
  }

  .book-back p {
    position: relative; /* Existing properties */
    z-index: 2 !important;
    font-size: .4cm;
    padding: 0;
    color: var(--text1);
    display: -webkit-box;
    -webkit-line-clamp: 30; /* Number of lines after which to show ellipsis */
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3600px; /* Example: 18px line-height * 3 lines */
    line-height: 20px;
    width: 100%;
    text-align: center;
  }
  
  