/* Loader.css */
@keyframes ellipsis {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ellipsisTwo {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes ellipsisThree {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}

.light .loader div {
  position: absolute;
  top: 33%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #333;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.dark .loader div {
  position: absolute;
  top: 33%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ffffff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div:nth-child(1) {
  left: 8px;
  animation: ellipsis 0.6s infinite;
}

.loader div:nth-child(2) {
  left: 8px;
  animation: ellipsisTwo 0.6s infinite;
}

.loader div:nth-child(3) {
  left: 32px;
  animation: ellipsisTwo 0.6s infinite;
}

.loader div:nth-child(4) {
  left: 56px;
  animation: ellipsisThree 0.6s infinite;
}
