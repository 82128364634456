.rating-bar-container {
    position: relative;
    display: inline-block; /* Keeps the component inline and allows for positioning of the tooltip */
    margin-top: 8px;
    color: var(--text1);
    font-size: 0.7em;
  }
  
  .rating-tooltip {
    visibility: hidden;
    background-color: var(--bg);
    color: var(--text1);
    text-align: center;
    border-radius: 5px;
    padding: 0px 3px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(-100%, -50%);
    white-space: nowrap;
    margin-left: -10px; /* Adjust for spacing from the rating bar */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    font-size: 0.9em;
    left: 8px;
  }
  
  .rating-bar-container:hover .rating-tooltip {
    visibility: visible;
  }
  
  .rating-bar-outer {
    background-color: transparent; /* Adjust color to match the uploaded image */
    height: 20px; /* Adjust height to match the uploaded image */
    width: 100px; /* Adjust width based on your preference */
    margin-left: 5px; /* Space for tooltip, adjust as needed */
    border: 1px solid var(--tag); /* Adjust color and width as desired */
    border-radius: 5px; /* Larger border radius for rounder edges */
  }
  
  .rating-bar-inner {
    background-color: var(--tag); /* Adjust color to match the uploaded image */
    height: 100%;
    border-radius: 4px 0 0 4px; /* Rounded corners on the left side */
    display: flex;
    align-items: center;
    position: relative; /* Relative to the outer bar */
  }
  
  .rating-bar-source, .rating-bar-value {
    padding: 0 5px; /* Padding inside the filled area */
  }
  
  .rating-bar-value {
    margin-left: auto;
    position: relative;
  }

  .rating-tooltip::after {
    content: "";
    position: absolute;
    top: 53%;
    left: 95%; /* Positioned at the left side of the tooltip */
    transform: translateY(-50%);
    border-width: 6.5px;
    border-style: solid;
    border-color: transparent transparent transparent var(--bg); /* The fourth border is the color of the tooltip */
    z-index: -1; /* Ensure it's under the tooltip */
  }