
.no-padding{
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.slider-label{
  color: var(--text1)
}

.space{
  margin-bottom: 15px;
}

.space-slider{
  margin-bottom: 30px;
}

.input-group{
  display: flex;
  flex-direction: column;
}

.desktop .double-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .2rem;
}

.mobile .double-container{
  display:flex;
  flex-direction: column;
  margin-top: .8rem;
  margin-bottom: .8rem;
  row-gap: .5rem;
}

.slider-container {
  display: grid;
  grid-template-columns: 120px 1fr; /* Adjust 150px to fit your longest label */
  align-items: center;
  gap: 15px;
}

.slider-container label {
  white-space: nowrap; /* Prevents the label from wrapping */
}


.form-control{
  width: 50% !important;
}

/* Styling for the toggle button */
.desktop-theme-toggle {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: none;
  background-color: transparent; /* Ensure this is transparent, not 'none' */
  color: #333;
  border-radius: 20px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  transition: background-color 0.3s; /* Animation for background color change */
}

.mobile-theme-toggle {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: none;
  background-color: transparent; /* Ensure this is transparent, not 'none' */
  color: #333;
  border-radius: 20px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  transition: background-color 0.3s; /* Animation for background color change */
}


/* Hover color for light theme toggle */
.light .desktop-theme-toggle:hover{
  background-color: #e6e6e6;
}

/* Hover color for dark theme toggle */
.dark .desktop-theme-toggle:hover{
  background-color: #4d4d4d;
}

.light .mobile-theme-toggle:active{
  background-color: #e6e6e6;
}

/* Hover color for dark theme toggle */
.dark .mobile-theme-toggle:active{
  background-color: #4d4d4d;
}

.btn-primary {
  background-color: var(--tag) !important;
  color: var(--text2) !important;
}

.btn-primary:hover{
  background-color: var(--tag-hover) !important;
}

.buttons {
  display: inline-block;
  background-color: var(--tag);
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  border-radius: 5px;
  font-size: 0.75rem;
  color: var(--text2);
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.buttons-title {
  display: inline-block;
  padding: 0.25rem;
  font-size: 0.75rem;
  color: var(--text2);
  font-weight: bold;
}