/* BookModal.css */
.custom-modal-class .modal-content {
  background-color: var(--card); /* Your new background color */
}

.modal-book-cover img {
  max-width: 100%;
  max-height: 100%;
  height: auto; /* maintain aspect ratio */
  border-radius: 4px;
}

.modal-book-data,
.modal-book-rating {
  padding-top: 20px;
}

/* Custom styles for book data and rating */
.modal-book-data h4,
.modal-book-rating p {
  margin-bottom: 10px;
}

.modal-book-rating p {
  font-weight: bold;
}

.modal-body{
  padding: 10px !important;
}

.no-left-padding{
  padding-left: 0 !important;
}

.modal-book-data, .modal-book-rating {
  padding-top: 0px;
}

.bookAuthor-modal {
  font-size: 1.5rem;
  color: var(--text2);
  margin-bottom: 0;
}

.bookSeries-modal {
  color: var(--text2);
  font-style: italic;
}

.bookTitle-modal {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.1rem;
}

.genreTag-modal {
  display: inline-block;
  background-color: var(--tag);
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  border-radius: 15px;
  font-size: .85rem;
  color: var(--text2);
  cursor: default;
  font-weight: bold;
}

.genreTag-modal:hover {
  background-color: var(--tag-hover);
}

.desktop-bookGenres-modal {
  margin-top: 0.5rem;
  display: flex;          /* Use flexbox for inline arrangement */
  flex-wrap: wrap;        /* Allow wrapping of items */
  row-gap: 12px;
}

.rating-4-modal { background-color: rgb(76, 175, 80) !important;}
.rating-3-modal { background-color: rgb(139, 195, 74) !important;}
.rating-2-modal { background-color: rgb(255, 193, 7) !important;}

.ratingBadge-modal {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  font-size: 1.8em;
  margin-top: 20px;
}

.table{
  --bs-table-bg: var(--card) !important;
  --bs-table-color: var(--text2) !important;
  --bs-table-hover-color: var(--text1) !important;
  margin-top: 30px;
}

.table td, .table th {
  text-align: center;
}

.cell-content {
  position: relative;
  z-index: 1;
  background-color: transparent !important;
}

.cell-content:before {
  content: "";
  position: absolute;
  top: 2px; bottom: 2px; left: 2px; right: 2px;
  background-color: var(--badge4);
  border-radius: 8px; /* Adjust the radius as needed */
  z-index: -1;
}

.cell-content.rating-4-modal:before {
  background-color: rgba(76, 175, 80, 0.5);
}

.cell-content.rating-3-modal:before {
  background-color: rgb(139, 195, 74, 0.5);
}

.cell-content.rating-2-modal:before {
  background-color:  rgba(255, 193, 7, 0.5);
}

.table-hover tbody tr:last-child:hover {
  --bs-table-hover-bg: transparent !important;
  --bs-table-hover-color: var(--text2) !important;
}

.table tbody tr:last-child td:not(:first-child),
.table tbody tr:last-child th:not(:first-child) {
  border-top: 1px solid var(--text2);
}

.white{
  --bs-table-color: var(--text1) !important;
  --bs-table-hover-color: var(--text1) !important;
}

.black{
  --bs-table-color: var(--text1) !important;
  --bs-table-hover-color: var(--text1) !important;
}

.table a {
  text-decoration: none; /* Remove underline */
  color: inherit;
}

@media (max-width: 780px) {
  .hide-on-mobile {
    display: none !important;
  }
}

a {
  text-decoration: none !important; /* Remove underline */
}

.custom-close {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 12px; /* Add some padding to increase the clickable area */
  border: none;
  background: transparent;
  color: #aaa; /* Customize as needed */
  font-size: 24px; /* Customize as needed */
  z-index: 2; /* Ensure it's above other content */
}

.mobile-close {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 12px; /* Add some padding to increase the clickable area */
  border: none;
  background: transparent;
  color: #aaa; /* Customize as needed */
  font-size: 24px; /* Customize as needed */
  z-index: 2; /* Ensure it's above other content */
}

.modal-book-cover.mobile-book-cover{
  padding-right: 0;
}

.separate-logos-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.separate-logos-row-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.separate-logos-row-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 8px;
}

.push-right{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.push-left{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.logos {
  height: 55px; /* Adjusts the size of the image */
  cursor: pointer;
  border-radius: 10px;
}

.small-text {
  font-size: 9px
}

.hide {
  display: none;
}

.center-contents{
  display: flex;
  flex-direction: column;
  align-items: center;
}