.share-button {
  padding: 1px 15px;
  font-size: 15px;
  cursor: pointer;
  border: none;
  background-color: rgba(255, 255, 255, 0.7); /* Ensure this is transparent, not 'none' */
  color: black;
  border-radius: 20px;
  position: fixed;
  left: 15px;
  bottom: 15px;
  transition: background-color 0.3s; /* Animation for background color change */
}


/* Hover color for light theme toggle */
.share-button:active{
  background-color: var(--tag-hover);
  color: blue;
}